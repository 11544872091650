.interes-form {
  max-width: 840px;
  width: 100%;
}

.interes-form__options-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.interes-form__title {
  margin-bottom: 40px;
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 24px;
  color: #FFFFFF;
}

.interes-form__checkbox {
  visibility: hidden;
  position: absolute;
  z-index: -10;
  opacity: 0;
}


.interes-form__option-item {
  margin-bottom: 10px;
  width: 100%;
  max-width: 400px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  font-family: 'Gothic A1';
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  position: relative;
}


.interes-form__circle {
  /* margin-right: 15px; */
  width: 24px;
  height: 24px;
  border: 2px solid var(--color-beige);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.interes-form__circle::after {
  /* visibility: hidden;
  opacity: 0; */
  content: '';
  display: none;
  width:  12px;
  height: 12px;
  background-color: var(--color-gold-light);
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0);
}

.interes-form__option-item.active .interes-form__circle {
  border: 2px solid var(--color-gold-light);
}

.interes-form__option-item.active .interes-form__circle::after {
  display: block;
}