.interes-selected-preview {
  max-width: 615px;
  width: 100%;
}

.interes-selected-preview__title {
  margin-bottom: 50px;
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 24px;
  color: #FFFFFF;
}

.interes-selected-preview__items-wrapper {
  
}


/* item */

.interes-selected-preview__item {
  padding-right: 20px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.interes-selected-preview__item:not(.interes-selected-preview__item:last-child)::after {
  content: '';
  width: 100%;
  height: 1px;
  display: block;
  background-color: var(--color-gold-light);

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}


.interes-selected-preview__item-title-block {
  padding-left: 85px;
  color: #fff;
  font-family: 'Gothic A1';
  font-size: 21px;
  position: relative;
}
.interes-selected-preview__item-title-block h3 {
  font-weight: 400;
}
.interes-selected-preview__item-title-block span {
  font-weight: 700;
}

.interes-selected-preview__item-title-block::before {
  content: '';
  width: 53px;
  height: 40px;
  display: block;
  background: url('./images/selected-icon.svg') center no-repeat;
  background-size: cover;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}



/* card stars (кол-во видео по теме) */
.interes-selected-preview__item-cards-count {
  display: flex;
}

.interes-selected-preview__item-card-star {
  margin-left: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--color-beige);
}