.topic-theme-section {
  margin-bottom: 100px;
  opacity: 1;
  transition: .2s linear;
}

.topic-theme-section.actual {
  order: -1 !important;
}

.topic-theme-section__info {
  margin: 0 auto 30px;
  /* margin-bottom: 30px; */
  width: 100%;
  max-width: 1525px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.topic-theme-section__title {
  margin-right: 50px;
  font-family: 'Fjalla One';
  font-size: 46px;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

.topic-theme-section__video-count {
  font-family: 'Gothic A1';
  font-weight: 700;
  font-size: 24px;
  color: #FFF2D5; /* Primery Color (3) todo */
}



/* Переключение Active / inactive */
.topic-theme-section.smooth-hide {
  opacity: 0;
}