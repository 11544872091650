.player-page {
  padding: 100px 0 0;
}

.player-page__player-wrapper {
  margin: 0 auto;
  width: 900px;
}

.player-page__buttons {
  margin: 50px auto 0;
  width: 900px;
  display: flex;
  gap: 10px;
}