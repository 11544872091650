.intro {
  height: 100vh;
  background-color: #010001;
}

.intro__inner {
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: calc(1340px + 40px + 40px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.intro__logo-img {
  /* margin-bottom: 100px; */
  width: 600px;
  height: auto;
}

.intro__logo-text {
  font-family: 'Gothic A1';
  font-weight: 500;
  font-size: 21px;
  text-align: center;
}


.intro__logo-wrapper {
  width: 650px;
}

.intro__animated-logo {
  width: 100%;
  height: auto;
}