.swiper-topic .swiper-slide {
  width: fit-content;
}

.swiper-topic {
  position: relative;
}

.swiper-topic::before,
.swiper-topic::after {
  content: '';
  display: block;
  width: 40vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}

.swiper-topic::before {
  right: 98%;
  background: linear-gradient(to left, transparent 0px, var(--color-black) 100px);
}
.swiper-topic::after {
  left: 98%;
  background: linear-gradient(to right, transparent 0px, var(--color-black) 100px);
}

.swiper {
  overflow: visible;
}


/* стрелки штатные (решили отключить) */
.swiper-button-next,
.swiper-button-prev {
  color: var(--color-gold-light);
  display: none;
}


/* Стрелки из нового дизайна */
.swiper-topic__arrow-left,
.swiper-topic__arrow-right {
  width: 53px;
  height: auto;
  display: block;
  cursor: pointer;

  position: absolute;
  top: 50%;
  left: 10px;
  z-index: 10;
  transform: translateY(-50%);
  opacity: 0;
  transition: .2s linear;
}

.swiper-topic__arrow-right {
  left: auto;
  right: 10px;
}

.swiper-topic:hover .swiper-topic__arrow-left,
.swiper-topic:hover .swiper-topic__arrow-right {
  opacity: 1;
}

.swiper-topic:hover .swiper-topic__arrow-left.swiper-button-disabled,
.swiper-topic:hover .swiper-topic__arrow-right.swiper-button-disabled {
  opacity: .2;
}