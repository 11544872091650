.quiz-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: var(--color-black);

  display: flex;
  justify-content: center;
  align-items: center;
}


.quiz-modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate3d(-50%, -50%, 0);
  text-align: center;
  color: #FFFFFF;
}

.quiz-modal__content-result {
  margin-bottom: 80px;
  font-weight: 800;
  font-size: 24px;
}

.quiz-modal__content-phrase {
  margin-bottom: 85px;
  font-family: 'Gothic A1';
  font-weight: 700;
  font-size: 40px;
}



/* btn */
.quiz-modal__buttons-wrapper {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 115px;

  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 930px;
}

.quiz-modal__btn {
  padding: 20px 40px;
  width: 210px;
  height: 65px;
  border: 2px solid var(--color-gold-light);
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  column-gap: 10px;
  transition: .2s linear;
  cursor: pointer;
}

.quiz-modal__btn--gold {
  background-color: var(--color-gold-light);
  color: var(--color-black);
}

.quiz-modal__btn--gold:hover {
  border: 2px solid #fff;
}