.settings-interes {
  max-width: 855px;
  width: 100%;
}

.settings-interes__gold-line {
  margin: 80px 0 70px;
  width: 100%;
  height: 1px;
  background-color: var(--color-gold-light);
}

