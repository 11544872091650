:root {
  --panel-padding: 32px;
  --svg-color-usual:  invert(94%) sepia(41%) saturate(507%) hue-rotate(304deg) brightness(103%) contrast(105%);
  --svg-color-active: invert(82%) sepia(31%) saturate(742%) hue-rotate(339deg) brightness(104%) contrast(101%);
}

.side-panel {
  width: 320px;
  height: 100%;
  padding-top: 206px;
  background: rgba(221, 221, 221, 0.12);
  font-family: 'Avenir'; 
  color: var(--color-beige);

  position: fixed;
  top: 0;
  left: 0;
}

.side-panel__title {
  margin: 0 auto 108px;
  color: var(--color-primary-2);
  font-family: 'Gothic A1';
  font-weight: 600;
  font-size: 35px;
  text-align: center;
}

.side-panel__data-wrapper {
  margin: 0 78px 0 auto;
  width: fit-content;
}


/* data-item */
.side-panel__data-item {
  margin-bottom: 45px;
}

/* item title */
.side-panel__data-item-title {
  width: fit-content;
  padding-left: var(--panel-padding);
  font-weight: 500;
  font-size: 21px;
  line-height: 1.5;
  text-align: left;
  position: relative;
  transition: .1s linear;
  cursor: pointer;
}

.side-panel__data-item-title img {
  filter: var(--svg-color-usual);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  transition: .1s linear;
}

.side-panel__data-item-title a {
  color: var(--color-beige);
  text-decoration: none;
}

/* list */
.side-panel__data-item ul {
  padding-left: var(--panel-padding);
}

.side-panel__data-item ul li:not(
  .side-panel__data-item ul li:last-child
) {
  margin: 5px 0 5px 0;
}




/* hover */
/* использовал этот сервис: https://codepen.io/sosuke/pen/Pjoqqp */

.side-panel__data-item-title:hover,
.side-panel__data-item-title:hover a,
.side-panel__data-item-title.active a {
  color: var(--color-gold-light);
}

.side-panel__data-item-title:hover img,
.side-panel__data-item-title.active img {
  filter: var(--svg-color-active)
}

/* .side-panel__data-item-title.active a {
  color: 
} */