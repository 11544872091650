.btn {
  margin: 0 auto;
  width: 100%;
  max-width: 330px;
  height: 60px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Avenir';
  font-size: 21px;
  font-weight: 500;
  text-decoration: none;
  color: var(--color-black-brand);

  background-color: var(--color-gold-light);
  border: 1px solid var(--color-gold-light);
  border-radius: 5px;
  transition: .2s linear;

  cursor: pointer;
}
 
.btn:hover {
  background-color: var(--color-gold-light);
  border: 1px solid #fff;
  color: #fff;
}

.btn--inactive {
  background-color: var(--color-gray);
  border: 1px solid var(--color-gray);
  color: #fff;
}

.btn:active {
  background-color: var(--color-gold-light);
}


.btn--white {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
}

.btn--white:hover {
  border: 1px solid var(--color-gold-light);
  background-color: #fff;
  color: #000;
}