.auth-form {
  width: 100%;
  max-width: 545px;

  font-family: 'Gothic A1', sans-serif;
  text-align: center;
  line-height: 1.2;
  color: var(--color-deactive);
}


.auth-form__title {
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 40px;
}

.auth-form__subtitle-gray {
  margin-bottom: 25px;
  font-family: 'Gothic A1';
  font-weight: 500;
  font-size: 17px;
  line-height: 170%;
  text-align: center;

  color: #FFFFFF;
}

.auth-form__subtitle {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 17px;
}

.auth-form__input {
  margin: 0 auto;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  max-width: 330px;

  height: 60px;
  border: 1px solid #fff;
  background-color: var(--color-black-brand);

  font-family: 'Avenir';
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  color: #fff;

  border-radius: 5px;
  outline: none;
  transition: .2s linear;
}

.auth-form__input:focus {
  border: 1px solid var(--color-gold-light)
}



.auth-form__input::placeholder {
  color: var(--color-deactive);
}