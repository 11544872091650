:root {
  --svg-color-usual:  invert(94%) sepia(41%) saturate(507%) hue-rotate(304deg) brightness(103%) contrast(105%);
  --svg-color-active: invert(82%) sepia(31%) saturate(742%) hue-rotate(339deg) brightness(104%) contrast(101%);
}

.header {
  width: 100%;
  background-color: var(--color-black);
  height: 80px;

  font-family: 'Gothic A1', sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 1.2;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.header a {
  color: inherit;
  text-decoration: none;
}

.header__inner {
  width: 100%;
  height: 100%;
  padding-right: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* logo */
.header__logo-wrapper {
  display: flex;
  align-items: flex-end;
}
.header__logo-wrapper span {
  color: transparent;
  font-size: 17px;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: url('./images/gold-background.jpg');
}


/* login */
.header__user-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  position: relative;
}
.header__user-text {
  margin-right: 13px;
}


/* header menu */
.header-menu {
  visibility: hidden;
  opacity: 0;
  padding: 35px 23px 20px 23px;
  min-width: 210px;
  width: fit-content;
  /* height: 0; */
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-black);
  z-index: 1;
  transition: .2s linear;
  overflow: hidden;
}

.header__user-wrapper.active .header-menu {
  visibility: visible;
  opacity: 1;
  /* height: 150px; */
}



.header-menu__item:not(.header-menu__item:last-child) {
  margin-bottom: 47px;
}

.header-menu__item img {
  margin-right: 13px;
  filter: var(--svg-color-usual);
}

.header-menu__item:hover {
  color: var(--color-gold-light)
}
.header-menu__item:hover img {
  filter: var(--svg-color-active);
}
