.settings-profile {
  margin-bottom: 175px;
}

/* секции целиком */
.settings-profile__section {
  margin-bottom: 50px;
}

.settings-profile__title-wrapper {
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.settings-profile__title-text {
  font-family: 'Gothic A1';
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
}
.settings-profile__title-line {
  margin-left: 55px;
  max-width: 722px;
  width: 100%;
  height: 1px;
  background-color: var(--color-primary-2);
}


/* photo section */
.settings-profile__photo-wrapper {
  margin-top: 80px;
  margin-bottom: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.settings-profile__photo-btn {
  max-width: 208px;
  background-color: var(--color-primary-2);
  margin: 0 0 0 55px;
}