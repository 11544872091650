.dashboard {
  padding-top: 110px;
  position: relative;
}


.dashboard__media-content {
  margin-bottom: 140px;
}

.dashboard__inner {
  overflow: hidden;
  margin: 0 auto;
  max-width: 1525px;
  width: 100%;
}

.dashboard__inner-topic-section {
  overflow: visible;
  max-width: 1625px;
  display: flex;
  flex-direction: column;
}

.dashboard__actual-theme-title {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto 50px;

  font-family: 'Fjalla One';
  font-weight: 400;
  font-size: 60px;
  line-height: 1.2;
}


.overflow-container {
  overflow: hidden;
}