.settings {
  min-height: 100vh;
  padding: 80px 20px 0 320px;
  display: flex;
}

.settings__content {
  padding: 90px 0 0;
  margin: 0 auto 0 160px;
  height: 100%;
  max-width: 1072px;
  width: 100%;
}