
/*  Здесь присутствуют:
    - Gothic A1
    - Avenir
    - Fjalla One
    - Poppins
*/


/* Gothic A1 */
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-100-Thin.ttf') format('TrueType');
  font-weight: 100;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-200-ExtraLight.ttf') format('TrueType');
  font-weight: 200;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-300-Light.ttf') format('TrueType');
  font-weight: 300;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-400-Regular.ttf') format('TrueType');
  font-weight: 400;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-500-Medium.ttf') format('TrueType');
  font-weight: 500;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-600-SemiBold.ttf') format('TrueType');
  font-weight: 600;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-700-Bold.ttf') format('TrueType');
  font-weight: 700;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-800-ExtraBold.ttf') format('TrueType');
  font-weight: 800;
  font-style: normal;
}
@font-face { 
  font-family: 'Gothic A1';
  src: url('./GothicA1/GothicA1-900-Black.ttf') format('TrueType');
  font-weight: 900;
  font-style: normal;
}




/* Avenir */
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-200-Light.ttf') format('TrueType');
  font-weight: 200;
  font-style: normal;
}
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-300-Book.ttf') format('TrueType');
  font-weight: 300;
  font-style: normal;
}
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-400-Roman.ttf') format('TrueType');
  font-weight: 400;
  font-style: normal;
}
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-500-Medium.ttf') format('TrueType');
  font-weight: 500;
  font-style: normal;
}
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-700-Heavy.ttf') format('TrueType');
  font-weight: 700;
  font-style: normal;
}
@font-face { 
  font-family: 'Avenir';
  src: url('./Avenir/Avenir-900-Black.ttf') format('TrueType');
  font-weight: 900;
  font-style: normal;
}




/* Fjalla One */
@font-face { 
  font-family: 'Fjalla One';
  src: url('./FjallaOne/FjallaOne-400-Regular.ttf') format('TrueType');
  font-weight: 400;
  font-style: normal;
}




/* Poppins */
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-100-Thin.ttf') format('TrueType');
  font-weight: 100;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-100-ThinItalic.ttf') format('TrueType');
  font-weight: 100;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-200-ExtraLight.ttf') format('TrueType');
  font-weight: 200;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-200-ExtraLightItalic.ttf') format('TrueType');
  font-weight: 200;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-300-Light.ttf') format('TrueType');
  font-weight: 300;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-300-LightItalic.ttf') format('TrueType');
  font-weight: 300;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-400-Regular.ttf') format('TrueType');
  font-weight: 400;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-400-Italic.ttf') format('TrueType');
  font-weight: 400;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-500-Medium.ttf') format('TrueType');
  font-weight: 500;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-500-MediumItalic.ttf') format('TrueType');
  font-weight: 500;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-600-SemiBold.ttf') format('TrueType');
  font-weight: 600;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-600-SemiBold.ttf') format('TrueType');
  font-weight: 600;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-700-Bold.ttf') format('TrueType');
  font-weight: 700;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-700-BoldItalic.ttf') format('TrueType');
  font-weight: 700;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-800-ExtraBold.ttf') format('TrueType');
  font-weight: 800;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-800-ExtraBoldItalic.ttf') format('TrueType');
  font-weight: 800;
  font-style: italic;
}

@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-900-Black.ttf') format('TrueType');
  font-weight: 900;
  font-style: normal;
}
@font-face { 
  font-family: 'Poppins';
  src: url('./Poppins/Poppins-900-BlackItalic.ttf') format('TrueType');
  font-weight: 900;
  font-style: italic;
}
