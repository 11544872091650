.topic-card {
  max-width: 360px;
  height: fit-content;
  display: block;
  overflow: hidden;
  border-radius: 20px;
  background-color: var(--color-black);
  position: relative;

  cursor: pointer;
  border: 3px solid transparent;

  transition: .1s linear;
}

.topic-card:hover {
  border: 3px solid var(--color-primary);
}


/* изображения */
.topic-card__img {
  display: block;
  width: 100%;
  height: auto;
  /* height: 100%; */
  transition: .2s linear;
  z-index: 1;
}

.topic-card__lock-icon {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.65);
  transform-origin: top right;
  transition: opacity .2s linear, transform .1s linear;
  z-index: 2;
}

.topic-card:hover .topic-card__lock-icon {
  transform: scale(1);
  transform-origin: top right;
}

.topic-card__pixel-icon {
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
}

.topic-card__img-active-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
}


/* модификаторы */
/* Просматриваемое */
.topic-card.active {
  border: 3px solid var(--color-primary);
}

.topic-card.active .topic-card__img-active-logo {
  visibility: visible;
  opacity: 1;
}


/* Пиксели */
.topic-card.new .topic-card__pixel-icon {
  display: block;
}

/* Блокировка */
.topic-card.blocked .topic-card__lock-icon {
  visibility: visible;
  opacity: 1;
}

.topic-card.blocked .topic-card__img {
  opacity: 1;
}

.topic-card.blocked .topic-card__progress-bar-outer {
  visibility: hidden;
  opacity: 0;
}

.topic-card.blocked .topic-card__pixel-icon {
  display: none;
}


/* не показывать органы управления на превью */
.player:hover .player__screen-jump-back,
.player:hover .player__screen-jump-forward {
/* .player:hover .player__controls-panel, */
/* .player.mouse-moving:hover .player__controls-panel { */
  transition: 0;
  visibility: hidden;
}

.player:hover .player__controls-panel,
.player.mouse-moving:hover .player__controls-panel {
  display: none;
}
.player.full-screen .player__screen-jump-back,
.player.full-screen .player__screen-jump-forward {
  visibility: visible;
}

.player.full-screen:hover .player__controls-panel,
.player.full-screen.mouse-moving .player__controls-panel {
  display: flex;
}