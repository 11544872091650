.settings-form {
  max-width: 1300px;
  width: 100%;
}

.settings-form__groups-wrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 27px;
}

.settings-form__groups-wrapper--column {
  flex-wrap: nowrap;
  flex-direction: column;
}

/* form group */
.settings-form__group-inline {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  column-gap: 27px;
}

.settings-form__group-item {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
}

.settings-form__group-item label {
  margin-bottom: 10px;
  font-family: 'Gothic A1';
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: #ddd;
}

.settings-form__group-item input {
  padding: 20px 10px;
  width: 100%;
  height: 60px;

  font-weight: 200;
  font-size: 17px;
  line-height: 21px;
  color: var(--color-primary-2);

  display: flex;
  justify-content: flex-start;
  align-items: center;

  background-color: #151515;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  transition: .2s linear;
}



.settings-form__btn {
  margin: 50px 0 0 auto;
  max-width: 160px;
  background-color: var(--color-primary-2);
}


.settings-form.error input {
  border: 1px solid red;
  color: red;
}

.settings-form.success input {
  border: 1px solid var(--color-gold-dark);
  background-color: var(--color-gold-light);
}