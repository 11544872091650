.player {
  position: relative;
  width: 100%;
  height: fit-content;
  /* border: 1px solid skyblue; */
  display: flex;
}

.player__video {
  width: 100%;
  height: auto;
  display: block;
}


/* screen wrapper */
.player__screen-panel {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.player__screen-item {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
}


.player__screen-play {
  position: relative;
}
.player__screen-play-img,
.player__screen-pause-img {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  transition: .05s linear;
}

.player__screen-play-img.playing,
.player__screen-pause-img.paused {
  visibility: visible;
  opacity: 1;
}

/* controls pannel */
.player__controls-wrapper {
  padding: 0 20px 20px;
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;
}

.player__controls-panel {
  margin: 0 auto;
  padding: 0 80px;
  max-width: 1480px;
  width: 100%;
  height: 118px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  background: rgba(45, 45, 45, 0.65);
  backdrop-filter: blur(10px);
  border-radius: 31px;
}

.player__controls-item {
  cursor: pointer;
}

.player__controls-play {
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* progress */
.player__controls-progress {
  max-width: 940px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.player__controls-progress input {
  margin: 0 8px;
  width: 100%;
}

.player__controls-progress input {
  -webkit-appearance: none !important;
  height: 10px;
  border-radius: 5px;
  /* background: var(--color-gold-light); */
  background: var(--color-beige);
}
.player__controls-progress input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  width: 24px;
  height: 24px;
  background: var(--color-gold-light);
  border-radius: 50%;
}

.player__controls-progress span {
  width: 75px;
  font-family: 'Poppins';
  font-size: 23px;
  line-height: 35px;
  color: var(--color-gold-light);
  pointer-events: none;
}


/* Close cross */
.player__close-cross {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 3;
}
/* full-screen */
.player.full-screen .player__close-cross {
  display: block;
}


/* движение курсора */
.player {
  cursor: none;
}
.player.mouse-moving {
  cursor: pointer;
}
/* Скрыть панели в покое */
.player__controls-panel,
.player__screen-panel,
.player__close-cross {
  opacity: 0;
  transition: .2s linear;
}
/* Показать панели при движении мыши  */
.player.mouse-moving .player__controls-panel,
.player.mouse-moving .player__screen-panel,
.player.mouse-moving .player__close-cross {
  opacity: 1;
}
/* курсор вне плеера */
.player.mouse-out .player__screen-panel {
  opacity: 1;
  visibility: visible;
}
.player.mouse-out .player__screen-item:not(.player__screen-play) {
  visibility: hidden;
}
