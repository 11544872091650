.topic-card__progress-bar-outer {
  width: 337px;
  height: 15px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 5px;

  background: rgba(136, 136, 136, 0.8);
  border-radius: 54px;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);

  transition: .2s linear;
}

.topic-card__progress-bar-inner {
  width: 75%;
  height: 5px;
  background-color: var(--color-primary-2);
  border-radius: 5px;

  transition: .2s linear;
}