.invitation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.9);

  display: flex;
  justify-content: center;
  align-items: center;
}


.invitation__dialog-window {
  padding: 40px 80px;
  /* width: 460px; */
  /* height: 275px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-gold-light);
  font-family: 'Gothic A1';
  color: #000;
}

.invitation__title {
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 24px;
}

.invitation__sub-title {
  /* margin-top: 40px; */
  margin-bottom: 60px;
  font-weight: 400;
  font-size: 24px;
  line-height: 170%;
  text-align: center;
}

.invitation__btn {
  max-width: 160px;
}