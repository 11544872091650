.briefing {
  margin: 0 auto;
  width: 100%;
  max-width: 1525px;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  font-family: 'Gothic A1', sans-serif;
  line-height: 1.2;
  color: #fff;
}


.briefing__video-wrapper {
  width: 100%;
  max-width: 1000px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}


.briefing__video-wrapper-bg {
  width: 100%;
  height: 100%;
  display: block;
}

.briefing__video-wrapper .player {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.briefing__video-arrow-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0);
  cursor: pointer;
} 

.briefing__video-cover {
  width: 100%;
  height: auto;
}


/* text block */
.briefing__text-wrapper {
  width: 100%;
  max-width: 435px;
}

.briefing__text-title {
  margin-bottom: 65px;
  font-size: 56px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
}


.briefing__text {
  margin-bottom: 35px;
  height: 160px;
  overflow: hidden;
  transition: .3s linear;
  position: relative;
}

.briefing__text::after {
  content: '';
  display: block;
  width: 100%;
  height: 40px;

  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-image: linear-gradient(to bottom, transparent, var(--color-black));
}

/* кнопка развернуть */
.briefing__read-more-btn {
  width: fit-content;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--color-gold-light);
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;

  cursor: pointer;
}
.briefing__read-more-btn:hover {
  text-decoration: underline;
}

.briefing__read-more-btn img {
  margin-left: 10px;
}




/* развернуть текст */
.deploy.briefing__text::after {
  display: none;
}

.briefing__text.deploy {
  height: 370px;
}

.briefing__text.deploy .briefing__read-more-btn img {
  transform: rotate(180deg);
}


/* полный экран */
.briefing__video-wrapper.fullscreen {
  position: static;
  /* margin: 0 auto;
  max-width: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  background: var(--color-black);
  border-radius: 0; */
}

.briefing__video-wrapper.fullscreen .player {
  /* position: fixed; */
  /* height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate3d(-50%, -50%, 0); */
}

.briefing__video-wrapper.fullscreen .player .player__video {
  /* margin: 0 auto;
  height: 100%;
  width: auto; */
}



/* quiz-preview */
.quiz-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  /* background-color: orange; */
  background: url('./images/briefing-cover.jpg') center no-repeat;
  background-size: cover;
  cursor: pointer;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}