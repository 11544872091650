:root {
  --color-gold-dark:   #ffae00;
  --color-gold-light:  #FFD374;
  --color-beige:       #FFF2D5;
  --color-black-brand: #151515;
  --color-gray:        #998C86;
  --color-deactive:    #ddd;
  

  --color-primary:    #ffae00;
  --color-primary-2:  #FFD374;
  --color-primary-3:  #FFF2D5;
  --color-black: #151515;
}



*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--color-black-brand);
  color: #fff;
}

body.fullscreen {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type='number'] {
  -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.img {
  display: block;
  /* max-width: 100%; */
  /* height: auto; */
}


.gold-letters {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-image: url('./assets/images/fonts-background/gold-background.jpg');
}

.section-inner {
  margin: 0 auto;
}



form input.error {
  border: 1px solid #ff0000;
}